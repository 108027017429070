import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import XIcon from 'src/components/icons/X.svg'
import FacebookIcon from 'src/components/icons/Facebook.svg'
import LinkedinIcon from 'src/components/icons/Linkedin.png'
import XIconLink from 'src/components/icons/XIconLink'
import FacebookIconLink from 'src/components/icons/FacebookIconLink'
import LinkedInIconLink from 'src/components/icons/LinkedinIconLink'

interface IJoinUsIconsProps {}

const JoinUsIcons: FC<IJoinUsIconsProps> = () => {
  const { t } = useTranslation('menu')

  return (
    <div className="text-navyBlueGray text-center flex justify-center items-center">
      <p className="uppercase">{t('Join us!')}</p>
      <XIconLink src={XIcon} className="w-5 ml-2" />
      <FacebookIconLink src={FacebookIcon} className="w-5 ml-2" />
      <LinkedInIconLink src={LinkedinIcon} className="w-5 ml-2" />
    </div>
  )
}

export default JoinUsIcons
